<template>
	<v-container>
		<material-card id="main-card" color="info" title="Избранное">
			<template v-slot:headerRight>
				<v-select v-model="section" :items="sections" item-text="name" item-value="value" dense></v-select>
			</template>
			<Progress :error="error" :loading="loading"></Progress>

			<v-flex xs12>
				<channel-list :items="items"></channel-list>
			</v-flex>
			<v-flex xs12 class="text-md-center">
				<scroll-pagination v-if="hasMorePage" :page="page" :loading="loading" @page="onScroll"></scroll-pagination>
			</v-flex>
		</material-card>
	</v-container>
</template>

<script>
import ChannelList from "../components/channel-list.vue";
import ScrollPagination from "../components/helper/scroll-pagination.vue";

export default {
	components: { ChannelList, ScrollPagination },
	props: {
		type: [String]
	},
	data() {
		return {
			loading: false,
			items: null,
			error: null,
			page: 1,
			hasMorePage: false,
			section: 'all',
			sections: [{value: 'all', name: 'Все'}, {value: 'series', name: 'Сериалы'}, {value: 'movie', name: 'Фильмы'}, {value: 'anime', name: 'Аниме'}]
		};
	},
	created() {
		var t = this;
		document.title = "Избранное";

		t.fetchData();
	},
	watch: {
		section() {
			this.page = 1;
			this.fetchData();
		}
	},
	methods: {
		fetchData() {
			var t = this;
			t.error = null;
			t.loading = true;

			window.ajax("/api/fave/list/" + t.section, { body: { page: t.page, type: t.type } }, function(
				data,
				error
			) {
				t.loading = false;
				if (!error) {
					//console.log(data);
					t.hasMorePage = data.meta.hasMore;
					if (t.page == 1) {
						t.items = data.data;
					} else {
						t.items = [...t.items, ...data.data];
					}
				} else {
					t.error = data;
				}
			});
		},
		onScroll(page) {
			this.page = page;
			this.fetchData();
		},
	},
};
</script>
<style scoped>
</style>